import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler vers le haut de la page
  }, [pathname]); // À chaque changement de route, l'effet s'exécute

  return null; // Ce composant n'a pas besoin de renvoyer un contenu
};

export default ScrollToTop;
