import React from 'react';

// Composant pour H1
export const H1 = ({ children, className = '', disableTextCenter = false }) => {
  return (
    <h1
      className={`text-4xl lg:text-5xl text-left font-bold text-camel mb-8 animate-fadeIn ${
        disableTextCenter ? 'text-left' : 'text-center'
      } ${className}  `}
    >
      {children}
    </h1>
  );
};

// Composant pour H2
export const H2 = ({ children, className = '' }) => {
  return (
    <h2
      className={`text-4xl sm:text-5xl text-center font-bold text-camel mb-8 animate-fadeIn ${className}`}
    >
      {children}
    </h2>
  );
};

// Composant pour H3
export const H3 = ({ children, className = '' }) => {
  return (
    <h3
      className={`text-2xl sm:text-3xl lg:text-4xl text-center font-bold text-camel mb-8 animate-fadeIn ${className}`}
    >
      {children}
    </h3>
  );
};
