import React, { useState, useRef } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaCode,
  FaSearch,
  FaBullhorn,
  FaCog,
} from 'react-icons/fa';
import { motion, useInView } from 'framer-motion';
import illustrationQuestion from '../assets/images/illustrations/illustration-question.svg';
import Section from '../components/Section';
import { H1, H2 } from '../components/Headings';
import CTA from '../components/CTA';
import TypewriterText from '../components/TypewriterText';
import FaqSection from '../components/FaqSection';

const servicesData = [
  {
    title: 'Développement web',
    description:
      "Création de sites vitrines, e-commerce, et d'applications web sur mesure.",
    link: '/services#developpement-web',
    icon: <FaCode size={40} className="text-brun" />,
  },
  {
    title: 'SEO & Marketing',
    description:
      'Optimisation pour les moteurs de recherche et stratégies marketing digital.',
    link: '/services#seo-marketing',
    icon: <FaSearch size={40} className="text-brun" />,
  },
  {
    title: 'Identité visuelle & Support print',
    description:
      'Création de votre identité visuelle avec des logos, charte graphique et supports imprimés.',
    link: '/services#identite-visuelle',
    icon: <FaBullhorn size={40} className="text-brun" />,
  },
  {
    title: 'Maintenance informatique',
    description:
      'Support technique et maintenance pour assurer la continuité de vos activités.',
    link: '/services#maintenance-informatique',
    icon: <FaCog size={40} className="text-brun" />,
  },
];

const AccordionItem = ({ stepNumber, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white shadow-lg rounded-lg mb-4">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <span className="text-camel text-lg font-bold mr-4">
            {stepNumber}
          </span>
          <h3 className="text-xl font-bold text-text text-left">{title}</h3>
        </div>
        {isOpen ? (
          <FaChevronUp className="text-camel" />
        ) : (
          <FaChevronDown className="text-camel" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 border-t border-gray-200">
          <p className="text-left">{content}</p>
        </div>
      )}
    </div>
  );
};

const Home = () => {
  const words = [
    'Rencontre |',
    'Étude du projet |',
    'Réalisation |',
    'Livraison |',
    'Accompagnement |',
  ];

  // Ref et état de visibilité pour les sections
  const servicesRef = useRef(null);
  const isInViewServices = useInView(servicesRef, { once: true });

  const processRef = useRef(null);
  const isInViewProcess = useInView(processRef, { once: true });

  return (
    <div>
      {/* Section 1: Introduction */}
      <Section bgColor="bg-white">
        <H1>
          Développeur web freelance <br /> Création de sites web & SEO
        </H1>
        <p className="text-lg mb-6 text-center">
          Je suis <strong>Sofiane</strong>, développeur web freelance avec plus
          de 6 ans d'expérience. J'accompagne mes clients dans la création de{' '}
          <strong>sites vitrines modernes</strong> et optimisés pour le{' '}
          <strong>SEO</strong>, en répondant à leurs besoins spécifiques.
        </p>
        <p className="text-lg mb-6 text-center">
          Polyvalent, je m'adapte à chaque projet, qu'il s'agisse de{' '}
          <strong>développement web</strong>, de{' '}
          <strong>référencement naturel</strong>, de{' '}
          <strong>marketing digital</strong> ou de{' '}
          <strong>maintenance informatique</strong>.
        </p>
        <p className="text-lg mb-6 text-center">
          Mon objectif : maximiser votre <strong>visibilité en ligne</strong> et
          vous fournir des solutions complètes pour réussir sur le web.
        </p>
        <div className="text-center px-4 md:px-8 lg:px-16 pt-8">
          <CTA
            className="inline bg-cta text-white hover:bg-white"
            text="Démarrer mon projet"
            link="/contact"
          />
        </div>
      </Section>

      {/* Section 2: Services avec animation */}
      <Section>
        <H2 className="text-white">Mes services</H2>
        <motion.div
          ref={servicesRef}
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-12 lg:px-24 lg:py-8"
          initial={{ opacity: 0 }}
          animate={
            isInViewServices ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
          }
          transition={{ duration: 1.2 }}
        >
          {servicesData.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 50 }}
              animate={isInViewServices ? { opacity: 1, y: 0 } : {}}
              transition={{
                duration: 0.8,
                delay: index * 0.2,
              }}
            >
              <div className="flex justify-center mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold text-camel mb-2">
                {service.title}
              </h3>
              <p className="text-md text-gray-600 mb-4">
                {service.description}
              </p>
              <a
                href={service.link}
                className="text-brun font-bold hover:underline"
              >
                En savoir +
              </a>
            </motion.div>
          ))}
        </motion.div>
        <div className="text-center">
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Découvrir mes services"
            link="/services"
          />
        </div>
      </Section>

      {/* Section 3: Comment ça marche ? avec animation */}
      <Section bgColor="bg-white">
        <H2>Comment ça marche ?</H2>
        <p className="text-lg mb-6 text-center">
          Profitez d'un accompagnement personnalisé, étape par étape, pour mener
          à bien votre projet web, du concept à la réalisation.
        </p>
        <motion.div
          ref={processRef}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          initial={{ opacity: 0 }}
          animate={
            isInViewProcess ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
          }
          transition={{ duration: 1.2 }}
        >
          <div className="home-container text-center lg:py-12">
            <div className="flex justify-center mb-6">
              <img
                src={illustrationQuestion}
                alt="Illustration question"
                className="max-w-full h-auto w-64 lg:w-80"
              />
            </div>
            <TypewriterText words={words} loop={true} />
          </div>
          <div className="max-w-4xl">
            <AccordionItem
              stepNumber="01"
              title="Rencontre"
              content="Établissons une première prise de contact pour comprendre vos objectifs et vos attentes."
            />
            <AccordionItem
              stepNumber="02"
              title="Étude du projet"
              content="J’analyse vos besoins en détail et rédige un cahier des charges."
            />
            <AccordionItem
              stepNumber="03"
              title="Conception & Réalisation"
              content="Je m'occupe du développement technique en respectant les meilleures pratiques de codage."
            />
            <AccordionItem
              stepNumber="04"
              title="Livraison"
              content="Votre projet est finalisé et prêt à être mis en ligne."
            />
            <AccordionItem
              stepNumber="05"
              title="Support & Suivi"
              content="Je reste à votre disposition pour la maintenance et l'optimisation."
            />
          </div>
        </motion.div>
        <div className="text-center pt-12">
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Je me lance !"
            link="/contact"
          />
        </div>
      </Section>

      <FaqSection />
    </div>
  );
};

export default Home;
