import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { H1, H2, H3 } from '../components/Headings';

const TypewriterText = ({ words, loop }) => {
  return (
    <div className="typewriter">
      <p className="text-4xl sm:text-5xl text-center font-bold text-camel mb-8">
        <Typewriter
          words={words}
          loop={loop}
          cursor
          cursorStyle="|"
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </p>
    </div>
  );
};

export default TypewriterText;
