import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Section from './Section';
import { H2 } from '../components/Headings';
import CTA from '../components/CTA';

const FaqSection = () => {
  const faqs = [
    {
      question: 'Pourquoi le web pour mon entreprise ?',
      answer:
        "Le web est une opportunité immense pour votre entreprise. Il permet une accessibilité 24/7 et une présence en ligne qui renforce la visibilité et l'engagement client.",
    },
    {
      question: 'Comment réussir mon évolution digitale ?',
      answer:
        'Tout commence par une analyse précise de vos objectifs et des besoins de vos prospects. Ensemble, nous établirons une stratégie claire pour assurer votre croissance numérique.',
    },
    {
      question: 'Existe-t-il une solution à ma problématique ?',
      answer:
        'Nous travaillerons ensemble pour comprendre vos défis et proposer une solution adaptée et personnalisée afin de maximiser votre retour sur investissement.',
    },
  ];

  // Référence pour l'animation au scroll
  const faqRef = useRef(null);
  const isInViewFaq = useInView(faqRef, { once: true });

  return (
    <Section>
      {/* Conteneur avec dégradé en fond, adapté à la charte */}
      <motion.div
        ref={faqRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isInViewFaq ? { opacity: 1, y: 0 } : { opacity: 0 }}
        transition={{ duration: 1.2 }}
        className="w-full"
      >
        <H2 className="text-white">
          Des questions sur le web ? <br /> Quelques réponses
        </H2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-24 lg:py-8 mb-12">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 50 }}
              animate={isInViewFaq ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <h3 className="text-xl font-bold text-camel mb-2">
                {faq.question}
              </h3>
              <p className="text-md text-text">{faq.answer}</p>
            </motion.div>
          ))}
        </div>

        <div className="text-center">
          {/* CTA section */}
          <CTA
            className="inline bg-cta text-white hover:bg-white hover:text-camel transition-colors duration-300"
            text="Une autre question ?"
            link="/contact"
          />
        </div>
      </motion.div>
    </Section>
  );
};

export default FaqSection;
