import React from "react";
import Section from "../components/Section";
import illustrationContact from "../assets/images/illustrations/illustration-discuss-your-project.svg";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <div className="bg-background text-text lg:pt-12">
      <Section bgColor="bg-white" disablePaddingBottom={true}>
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between lg:gap-12">
          <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
            <img
              src={illustrationContact}
              alt="Illustration contact"
              className="max-w-full h-auto w-64 lg:w-80 mb-8 lg:mb-4"
            />
            <h1 className="text-4xl font-bold text-camel text-center lg:text-left mb-8 lg:mb-4 animate-fadeIn">
              Contactez-moi pour discuter de votre projet web
            </h1>
            <p className="text-lg text-center lg:text-left mb-8 lg:mb-4 animate-slideUp">
              Vous avez un projet en tête, que ce soit la création d’un site
              vitrine, e-commerce ou une stratégie SEO ? Je suis à votre
              disposition pour échanger et vous aider à réaliser votre vision.
              Remplissez le formulaire ci-dessous et je vous recontacterai
              rapidement.
            </p>
          </div>
          <div className="lg:w-1/2">
          <ContactForm endpoint="http://localhost:5001/contact" />
          </div>
        </div>
      </Section>
    </div>
  );
};
export default Contact;
