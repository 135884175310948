// src/components/CTA.js
import React from 'react';

const CTA = ({ text, link, className }) => (
  <div className={`text-center mt-8 ${className}`}>
    <a
      href={link}
      className={`bg-cta text-white font-bold inline-block py-3 px-6 rounded-lg border-2 border-cta hover:bg-white hover:border-2 hover:border-cta hover:text-cta transition-colors duration-300 ${className}`}
    >
      {text}
    </a>
  </div>
);

export default CTA;
