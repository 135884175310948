import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Importer useLocation pour détecter la page active
import logo from '../assets/images/logo-transparent.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Icônes pour le menu burger

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Utiliser useLocation pour obtenir le chemin actif

  // Fonction pour ouvrir/fermer le menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Fonction pour fermer le menu lorsqu'on clique sur un lien
  const closeMenu = () => {
    setIsOpen(false);
  };

  // Fonction pour vérifier si le lien est actif
  const isActive = (path) => location.pathname === path;

  return (
    <header className="bg-camel text-white px-6">
      <nav className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <Link to="/" title="Retour à l'accueil">
          <img src={logo} alt="Logo Sofiane Développeur Web" className="h-20" />
        </Link>

        {/* Bouton hamburger pour le menu mobile */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="focus:outline-none"
          >
            {isOpen ? <FaTimes size={30} className="text-white" /> : <FaBars size={30} className="text-white" />}
          </button>
        </div>

        {/* Liens du menu - Cachés en mobile, affichés en desktop */}
        <div className="hidden lg:flex space-x-6">
          <Link
            to="/"
            className={`${
              isActive('/') ? 'border-b-2 border-white' : 'hover:text-brun'
            } transition duration-300`}
            title="Accueil"
          >
            Accueil
          </Link>
          <Link
            to="/about"
            className={`${
              isActive('/about') ? 'border-b-2 border-white' : 'hover:text-brun'
            } transition duration-300`}
            title="À propos de moi"
          >
            À propos
          </Link>
          <Link
            to="/services"
            className={`${
              isActive('/services')
                ? 'border-b-2 border-white'
                : 'hover:text-brun'
            } transition duration-300`}
            title="Services"
          >
            Services
          </Link>
          <Link
            to="/projects"
            className={`${
              isActive('/projects')
                ? 'border-b-2 border-white'
                : 'hover:text-brun'
            } transition duration-300`}
            title="Projets"
          >
            Projets
          </Link>
          <Link
            to="/contact"
            className={`${
              isActive('/contact')
                ? 'border-b-2 border-white'
                : 'hover:text-brun'
            } transition duration-300`}
            title="Contact"
          >
            Contact
          </Link>
        </div>

        {/* Menu mobile - Affiché en mobile seulement */}
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } lg:hidden absolute top-16 left-0 w-full bg-camel mt-3 pb-6 z-50`}
        >
          <Link
            to="/"
            className={`${
              isActive('/') ? 'text-white font-extrabold' : ''
            } block text-lg text-center py-2 hover:text-brun`}
            onClick={closeMenu}
            title="Accueil"
          >
            Accueil
          </Link>
          <Link
            to="/about"
            className={`${
              isActive('/about') ? 'text-white font-extrabold' : ''
            } block text-lg text-center py-2 hover:text-brun`}
            onClick={closeMenu}
            title="À propos"
          >
            À propos
          </Link>
          <Link
            to="/services"
            className={`${
              isActive('/services') ? 'text-white font-extrabold' : ''
            } block text-lg text-center py-2 hover:text-brun`}
            onClick={closeMenu}
            title="Services"
          >
            Services
          </Link>
          <Link
            to="/projects"
            className={`${
              isActive('/projects') ? 'text-white font-extrabold' : ''
            } block text-lg text-center py-2 hover:text-brun`}
            onClick={closeMenu}
            title="Projets"
          >
            Projets
          </Link>
          <Link
            to="/contact"
            className={`${
              isActive('/contact') ? 'text-white font-extrabold' : ''
            } block text-lg text-center py-2 hover:text-brun`}
            onClick={closeMenu}
            title="Contact"
          >
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
