import React from 'react';
import Section from '../components/Section'; // Assuming you are using a Section component for consistency
import illustrationLegal from '../assets/images/illustrations/illustration-mention-legals.svg'; // Assurez-vous que l'illustration existe à cet emplacement

const LegalMentions = () => {
  return (
    <div className="bg-background text-text lg:pt-12">
      <Section bgColor="bg-white">
        {/* Image placée avant le titre */}
        <div className="flex justify-center mb-8">
          <img
            src={illustrationLegal}
            alt="Illustration légale"
            className="max-w-full h-auto w-32 lg:w-64"
          />
        </div>

        {/* Titre h1 */}
        <h1 className="text-4xl lg:text-5xl text-center font-bold text-camel mb-8 animate-fadeIn">
          Mentions légales
        </h1>

        <div className="text-lg">
          {/* Identification de l'éditeur du site */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Identification de l'éditeur
          </h2>
          <p>
            <strong>Nom de l'entreprise :</strong> Sofiane
            <br />
            <strong>Responsable de la publication :</strong> Sofiane A<br />
            <strong>Adresse :</strong> Bordeaux & Périphérie, 33000
            <br />
            <strong>Email :</strong> contact@sofiane.ovh
            <br />
            <strong>Numéro SIRET :</strong>
          </p>

          {/* Hébergement du site */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Hébergement du site
          </h2>
          <p>
            Le site est hébergé par :<br />
            <strong>Nom de l'hébergeur :</strong> OVH
            <br />
            <strong>Adresse :</strong> 2 rue Kellermann, 59100 Roubaix, France
            <br />
            <strong>Téléphone :</strong> 1007 (depuis la France)
          </p>

          {/* Propriété intellectuelle */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Propriété intellectuelle
          </h2>
          <p>
            Le site et chacun des éléments qui le composent, y compris mais sans
            s'y limiter, les textes, images, vidéos, graphismes, logos, icônes,
            et bases de données, sont la propriété exclusive de Sofiane ou de
            ses partenaires. Toute reproduction, représentation, modification,
            publication, adaptation, totale ou partielle des éléments du site,
            est interdite sans autorisation préalable.
          </p>

          {/* Données personnelles */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Données personnelles
          </h2>
          <p>
            Conformément à la réglementation européenne en matière de protection
            des données personnelles (RGPD), vous disposez d'un droit d'accès,
            de rectification, et de suppression des informations qui vous
            concernent. Pour exercer ces droits, contactez-nous à l'adresse
            suivante : contact@sofiane.dev.
          </p>

          {/* Limitation de responsabilité */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Limitation de responsabilité
          </h2>
          <p>
            Sofiane Développement Web ne saurait être tenu responsable de tout
            dommage direct ou indirect résultant de l'accès ou de l'utilisation
            du site, y compris l'inaccessibilité, les pertes de données, les
            virus ou autres dangers pouvant affecter l'équipement informatique
            de l'utilisateur.
          </p>

          {/* Liens externes */}
          <h2 className="text-3xl font-bold text-camel mt-8 mb-4">
            Liens externes
          </h2>
          <p>
            Le site peut contenir des liens vers d'autres sites. Sofiane
            Développement Web ne peut être tenu responsable du contenu de ces
            sites externes.
          </p>
        </div>
      </Section>
    </div>
  );
};

export default LegalMentions;
