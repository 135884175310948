import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const SliderComponent = ({ servicesData, carouselSettings }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    ...carouselSettings,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    arrows: false, // Désactiver les flèches par défaut
  };

  const nextSlide = () => {
    if (sliderRef.current && currentSlide < servicesData.length - 1) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="relative container mx-auto">
      {/* Slider */}
      <Slider ref={sliderRef} {...settings}>
        {servicesData.map((service, index) => (
          <div key={index} className="p-4">
            <div className="bg-white shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between min-h-[300px] h-full">
              <div className="flex justify-center mb-6">{service.icon}</div>
              <h3 className="text-2xl font-bold mb-2">{service.title}</h3>
              <p className="font-semibold">{service.description}</p>
              <p>{service.details}</p>
            </div>
          </div>
        ))}
      </Slider>

      {/* Chevron container - Centré en dessous du slider */}
      <div className="flex justify-center items-center mt-4 space-x-4">
        {/* Chevron gauche - Toujours affiché mais transparent si on ne peut pas revenir en arrière */}
        <button
          onClick={prevSlide}
          className={`${
            currentSlide > 0
              ? 'text-gray-700 hover:text-black'
              : 'text-gray-300 cursor-not-allowed'
          } focus:outline-none z-10 bg-white p-2 rounded-full shadow-lg`}
          disabled={currentSlide === 0} // Désactive le bouton s'il est au début
        >
          <FaChevronLeft size={30} />
        </button>

        {/* Chevron droit - Affiche uniquement si on peut avancer */}
        {currentSlide < servicesData.length - 1 && (
          <button
            onClick={nextSlide}
            className="text-gray-700 hover:text-black focus:outline-none z-10 bg-white p-2 rounded-full shadow-lg"
          >
            <FaChevronRight size={30} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SliderComponent;
