import React, { useState } from "react";

const ContactForm = ({ endpoint }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    consent: false, // Ajout du consentement RGPD
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value, // Gestion de la checkbox
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifiez que la case de consentement est cochée
    if (!formData.consent) {
      setErrorMessage("Vous devez accepter le traitement de vos données personnelles.");
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Une erreur est survenue lors de l’envoi du message.");
      }

      const data = await response.json();
      setSuccessMessage(data.message);
      setErrorMessage("");
    } catch (err) {
      console.error(err.message);
      setErrorMessage("Erreur lors de l'envoi du message.");
      setSuccessMessage("");
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto lg:mx-0 bg-white shadow-camel/40 shadow rounded-lg p-8 animate-fadeIn"
      >
        <div className="mb-6">
          <label
            className="block text-camel text-sm font-bold mb-2"
            htmlFor="name"
          >
            Nom
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            name="name"
            type="text"
            placeholder="Votre nom"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-camel text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            name="email"
            type="email"
            placeholder="Votre email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-camel text-sm font-bold mb-2"
            htmlFor="phone"
          >
            Téléphone
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            name="phone"
            type="tel"
            placeholder="Votre téléphone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-camel text-sm font-bold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
            placeholder="Décrivez votre projet ou votre demande"
            rows="5"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="flex items-center">
            <input
              className="mr-2 leading-tight"
              type="checkbox"
              name="consent"
              checked={formData.consent}
              onChange={handleInputChange}
              required
            />
            <span className="text-sm text-gray-700">
              J'accepte le traitement de mes données personnelles conformément à la{" "}
              <a
                href="/politique-de-confidentialite"
                target="_blank"
                rel="noopener noreferrer"
                className="text-camel underline hover:text-camel-dark"
              >
                politique de confidentialité
              </a>
              {" "}afin de recevoir une réponse à ma demande.
            </span>
          </label>
        </div>
        <button
          className="bg-cta text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 w-full"
          type="submit"
        >
          Envoyer ma demande
        </button>
        {successMessage && (
          <p className="text-camel text-lg font-extrabold mt-4">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-cta text-lg font-bold mt-4">{errorMessage}</p>
        )}
      </form>
      <div className="mt-12 text-center lg:text-left">
        <p className="text-md">
          Ou contactez-moi directement à{" "}
          <strong>contact@sofiane.ovh</strong> pour discuter de votre projet.
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
